<template>
  <div class="search-container">
    <!-- Search Input -->
    <div class="search-box">
      <input
        type="text"
        v-model="query"
        @input="handleInput"
        placeholder="Namn på sektion eller förening..."
        class="search-input"
      />
      <v-icon slot="append">fas fa-search</v-icon>
    </div>

    <!-- Loader -->
    <div v-if="loading" class="d-flex justify-content-center mb-8 mt-8">
      <memlist-spinner />
    </div>

    <!-- Search Results -->
    <ul v-if="results.length && !loading" class="search-results">
      <li v-for="(result, index) in results" :key="index" @click="selectResult(result)">
        {{ result.name }}
      </li>
    </ul>

    <!-- Selected Entry -->
    <div v-if="selectedEntry" class="selected-entry">
      <strong>Vald:</strong> {{ selectedEntry.name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchCompanyInput',
  props: ['companies'],
  data() {
    return {
      query: '',
      results: [],
      loading: false,
      selectedEntry: null,
      debounceTimer: null
    };
  },
  methods: {
    handleInput() {
      if (this.query.trim() === '') {
        this.results = [];
        this.selectedEntry = null;
        return;
      }

      // Clear previous debounce timer
      clearTimeout(this.debounceTimer);

      // Set a slight delay before searching (prevents spam requests)
      this.debounceTimer = setTimeout(() => {
        this.search();
      }, 500); // 500ms delay
    },
    async search() {
      this.loading = true;
      this.results = [];

      const searchlist = this.companies.map(i => ({ name: i.name, company_id: i.company_id, frontend: i.frontend ? i.frontend : { display: true } }));

      this.results = searchlist.filter(i => i.frontend.display && i.name.toLowerCase().indexOf(this.query.toLowerCase()) >= 0);

      this.loading = false;
    },
    selectResult(result) {
      this.selectedEntry = result;
      this.results = []; // Hide results after selection

      this.$emit('selected', result.company_id);
    }
  }
};
</script>
<style scoped>
.search-container {

}

.search-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 5px;
  background: #fff;
  position: relative;
}

.search-input {
  flex: 1;
  border: none;
  outline: none;
  font-size: 16px;
  padding: 8px 40px 8px 10px; /* Prevents overlap with the icon */
  width: 100%;
}

.v-icon {
  position: absolute;
  right: 10px;
  font-size: 18px;
  color: #666;
}

/* Search Results */
.search-results {
  margin-top: 10px;
  padding: 0;
  list-style: none;
  border: 1px solid #ccc;
  border-radius: 5px;
  background: #fff;
}

.search-results li {
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
}

.search-results li:hover {
  background: #f0f0f0;
}

/* Selected Entry */
.selected-entry {
  margin-top: 10px;
  padding: 10px;
  background: #f6fdff;
  border: 1px solid #2D9BF0;
  font-size: 16px;
}

/* Mobile Fix */
@media (max-width: 480px) {
  .search-box {
    padding: 10px;
  }
  
  .search-input {
    padding-right: 35px; /* Adjust space for the icon */
  }
  
  .v-icon {
    right: 8px;
    font-size: 16px;
  }
}
</style>
