<template>
  <div>
    <ErrorPopup
      :error="error_popup_message"
    />

    <memlist-modal size="xl" ref="select-membership-modal" :visible="show_modal" @close="show_modal = false" >
      <div class="form-container">
        <h2>Välj Förening</h2>
        <p class="description">Sök upp en förening för att visa tillgängliga medlemskap</p>

        <SearchCompanyInput class="px-8" :companies="companies" @selected="company_selected" />

        
        <div v-if="company_id">
          
          <div class="mt-6">
            <AddCompanyMembershipCart 
              :company_id="company_id"
              @selected="selected_memberships"
            />
          </div>


          <div class="mt-6 px-8">
            <memlist-checkbox 
              text="Jag accepterar villkoren"
              v-model="accepted_terms"
            />
          </div>

          <div class="mt-6 mb-16 px-8">
            <memlist-button variant="primary" @click="continue_to_payment">Fortsätt till betalning</memlist-button>
          </div>

        </div>


        
      </div>
      
    </memlist-modal>
    <memlist-button variant="primary" @click="show_modal = true">Lägg till medlemskap</memlist-button>
  </div>
</template>

<script>
import axios from 'axios';
import SearchCompanyInput from './SearchCompanyInput.vue';

import AddCompanyMembershipCart from './AddCompanyMembershipCart.vue';
import ErrorPopup from '@/view/components/ErrorPopup.vue';


export default {
  name: 'AddCompanyMembershipModal',
  components: {
    SearchCompanyInput,
    AddCompanyMembershipCart,
    ErrorPopup
  },
  data() {
    return {
      companies: [],
      company_id: null,
      shop_item_ids: [],
      accepted_terms: false,
      show_modal: false,
      error_popup_message: null
    }
  },
  mounted() {
    this.fetch_companies();
  },
  methods: {
    async fetch_companies() {
      const res = await axios.get('/company/publiclist');
      if (res.status === 200) {
        this.companies = res.data;
      }
    },

    company_selected(company_id) {
      this.company_id = company_id;
    },

    selected_memberships(cart) {
      this.shop_item_ids = cart.map(i => i.shop_item_id);
    },

    async continue_to_payment() {
      try {
        await this.create_order(this.company_id, this.shop_item_ids);
      }
      catch (err) {
        console.error('unable to create order', this.company_id, this.shop_item_ids, err);
      }
    },
    
    async create_order(company_id, shop_item_ids) {
      const res = await axios.post(`/shop_order/create`, {
        company_id,
        shop_item_ids
      });

      if (res.status === 201) {
        const order = res.data;
        this.$router.push(`/select-payment/${order.token}/${order.shop_order_id}`);
      }
      else {
        this.error_popup_message = 'ERR_UNABLE_CREATE_ORDER';

        this.$nextTick(()=>{ this.error_popup_message = null; });
      }
    },
  }
}
</script>
