<template>
  <div class="container">
    <b-modal
      :title="creating ? $t('MEMBER_RELATION.CREATING') : $t('MEMBER_RELATION.EDITING')"
      ref="edit-form"
      hide-footer
      >

      <!-- Edit form -->
      <MemberFamilyEditor
        v-if="selected_member_relation"
        :member="member"
        :item="selected_member_relation"
        :creating="creating"
        @cancel="() => { this.$refs['edit-form'].hide(); }"
        @created="family_member_created"
        @updated="family_member_updated"
      />


    </b-modal>

    <b-row class="top-row noselect" @click.prevent="show_form = !show_form">

      <b-col cols="11">
        <v-list-item>
          <v-list-item-icon>
            <v-icon size="42">mdi-home-heart</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="fixed-font">{{$t('MEMBER.FAMILY')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </b-col>
      <b-col cols="1">
        <div class='justify-content-end d-flex'>
          <a v-if="show_form" href="#"
            class="mt-2">
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg style="fill: #919191" src="/assets/svg/angle-up-solid.svg"></inline-svg>
            </span>
          </a>

          <a v-if="!show_form" href="#"
            class="mt-2" >
            <span class="svg-icon svg-icon-md svg-icon-primary">
              <inline-svg  src="/assets/svg/angle-down-solid.svg"></inline-svg>
            </span>
          </a>

        </div>
      </b-col>
    </b-row>

    <div v-if="show_form">

      <b-table
        id="member-table"
        style="width: 100%; table-layout: fixed;"
        class="table-striped"
        :fields="fields"
        :items="items"
        head-variant="light"
        ref="memberTable"
      >
        <template #cell(actions)="row">
          <div class='d-flex justify-content-end'>
            <a
              v-if="!row.item.main"
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="edit_family_member(row.item)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
              </span>
            </a>
            <a
              v-if="!row.item.main"
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="delete_family_member(row.item)">
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
              </span>
            </a>
          </div>

        </template>

      </b-table>

      <p v-if="items.length === 0">{{$t('MEMBER.NO_FAMILY_MEMBERS')}}</p>


    </div>

    <hr/>
  </div>

</template>


<script>

import axios from 'axios';
import dayjs from 'dayjs';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';
import { is_mobile } from '@/core/services/utils';
import MemberFamilyEditor from './MemberFamilyEditor.vue';

export default {

  name: 'MemberTableFamily',

  props: ['member','items','company','expanded'],
  emits: ['save_data', 'created', 'updated'],
  mixins: [ toasts ],

  components: {
    MemberFamilyEditor
  },

  watch: {

  },

  computed: {
    is_mobile() {
      return is_mobile();
    },
  },

  methods: {

    async edit_family_member(member_relation_record) {
      try {
        this.selected_member_relation = member_relation_record;
        this.creating = false;

        this.$nextTick(()=>{
          this.$refs['edit-form'].show();
        });

      }
      catch (err) {
        console.error('edit_family_member error', err);
      }
    },

    family_member_created(family_member) {
      this.$emit('created', family_member);

      this.$refs['edit-form'].hide();
    },

    family_member_updated(family_member) {
      this.$emit('updated', family_member);

      this.$refs['edit-form'].hide();
    },

    async delete_family_member(member_id) {
      try {

      }
      catch (err) {
        console.error('delete_family_member error', err);
      }
    },


    async save_data() {

      this.$emit('save_data', this.member);

    }

  },

  mounted() {
    this.show_form = this.expanded;

    if (this.is_mobile) {
      this.fields = this.fields.filter((field) => {
        return field.mobile;
      });
    }
  },

  data() {
    return {

      creating: false,

      selected_member_relation: null,

      fields: [
        {
          key: 'member_id',
          label: this.$t('MEMBER.HD'),
          mobile: true,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.main ? this.$t('COMMON.YES') : this.$t('COMMON.NO');
          }
        },
        {
          key: 'rel_member.firstname',
          label: this.$t('MEMBER.FIRSTNAME'),
          mobile: true,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.main ? item.member.firstname : item.rel_member.firstname;
          }
        },

        {
          key: 'rel_member.lastname',
          label: this.$t('MEMBER.LASTNAME'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.main ? item.member.lastname : item.rel_member.lastname;
          }
        },

        {
          key: 'rel_member.email',
          label: this.$t('MEMBER.EMAIL'),
          sortable: false,
          mobile: false,
          tdClass: 'td-short2',
          thClass: 'td-short2',
          formatter: (_, __, item) => {
            return item.main ? item.member.email : item.rel_member.email;
          }
        },

        {
          key: 'actions',
          label: this.$t('COMMON.ACTIONS'),
          mobile: false,
          sortable: false,
          tdClass: 'td-short2',
          thClass: 'td-short2'
        },

      ],

      show_form: true,

    };
  }
};

</script>

<style lang="scss" scoped>
  
  @import "@/assets/sass/components/forms/_common_modals.scss";
</style>
