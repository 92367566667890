<template>
  <div class="page-container">

    <div v-if="loading">
      <memlist-spinner />
    </div>
    <div class="w-100" v-else>
      <div>

        <h3>Välj medlemskap</h3>
        <p>Välj något av nedanstående medlemskap för att fortsätta till betalning.</p>

      </div>

      <div class="section-container">
        <FormShopCompanySelection
          :companies="local_companies"
          @selected_membership="selected_membership"
        />
      </div>

      <div class="section-container">
        <FormShopExtraSelection 
          :extra_items="local_extra_items" 
          @selected="selected_extra_items"
          >
        </FormShopExtraSelection>
      </div>


      <div class="section-container">
        <FormShopCartSummaryTable :cart_items="cart_items" @remove-item="removeCartItem"></FormShopCartSummaryTable>
      </div>
    </div>
  </div>
</template>

<script>
import FormShopCartSummaryTable from '@/view/pages/public/forms/pages/shop/FormShopCartSummaryTable.vue';
import FormShopExtraSelection from '@/view/pages/public/forms/pages/shop/FormShopExtraSelection.vue';
import FormShopCompanySelection from '@/view/pages/public/forms/pages/shop/FormShopCompanySelection.vue';
import axios from 'axios';

export default {
  components: { 
    FormShopCartSummaryTable, 
    FormShopCompanySelection, 
    FormShopExtraSelection 
  },
  props: ['company_id'],
  watch: {
    company_id: {
      async handler(newVal) {

        this.loading = true;
        console.log('company_id changed', newVal)

        if (this.company_id) {
          await this.fetch_shop(this.company_id);
        }

        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      loading: true,
      local_extra_items: [
        
      ],
      local_companies: [
        {
          name: "Company A",
          company_id: 'a',
          show_error: false,
          items: [
            {
              shop_item_id: 1,
              name: 'Item 1 from Company A',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 1 from company A, this is a longer description, this is a longer description, this is a longer description this is a longer description.',
              dateFrom: '2024-01-01',
              dateTo: '2024-12-31',
              selected: false
            },
            {
              shop_item_id: 2,
              name: 'Item 2 from Company A',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 2 from company A',
              dateFrom: '2024-02-01',
              dateTo: '2024-11-30',
              selected: false
            }
          ],
        },
        {
          name: "Company B",
          company_id: 'b',
          show_error: false,
          items: [
            {
              shop_item_id: 3,
              name: 'Item 1 from Company B',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 1 from company B',
              dateFrom: '2024-03-01',
              dateTo: '2024-10-31',
              selected: false
            },
            {
              shop_item_id: 4,
              name: 'Item 2 from Company B',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 2 from company B',
              dateFrom: '2024-04-01',
              dateTo: '2024-09-30',
              selected: false
            },
            {
              shop_item_id: 5,
              name: 'Item 3 from Company B',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 3 from company B',
              dateFrom: '2024-05-01',
              dateTo: '2024-08-31',
              selected: false
            },
            {
              shop_item_id: 6,
              name: 'Item 4 from Company B',
              amount: 3,
              currency: 'SEK',
              description: 'Description for item 4 from company B',
              dateFrom: '2024-06-01',
              dateTo: '2024-07-31',
              selected: false
            },
          ],
        },
      ],

      cart_items: [
        
      ],
    };
  },
  methods: {

    async fetch_shop(company_id) {
      const res = await axios.post('/company/list_shop', { company_ids: [company_id] });
      if (res.status === 200) {
        this.shop_items = res.data;

        const company = res.data[0].company;

        this.local_companies = [
          {
            name: company.name,
            company_id: company.company_id,
            items: res.data[0].shop_items
          }
        ];
      }
    },

    validate() {
      for (const company of this.local_companies) {
        // check if there is a selected item
        const selected_item = company.items.find(item => item.selected);

        if (!selected_item) {
          company.show_error = true;
        }
      }

      window.scrollTo(0,0)

      this.local_companies = [ ...this.local_companies ];
    },
    selected_extra_items(shop_item_ids) {
      for (const item of this.local_extra_items) {
        item.selected = false;

        const exists = shop_item_ids.find(i => i === item.shop_item_id);
        item.selected = !!exists;

        if (item.selected) {
          const cart_item = this.cart_items.find(i => i.shop_item_id === item.shop_item_id);
          if (!cart_item) {
            this.cart_items.push(item);
          }
        }
        else {
          this.cart_items = this.cart_items.filter(i => i.shop_item_id !== item.shop_item_id);
        }
      }

      this.local_extra_items = [ ...this.local_extra_items ];
    },
    selected_membership(company_id, shop_item, shop_item_id, previous_shop_item_id) {
      const company = this.local_companies.find(item => item.company_id === company_id);
      const item = company.items.find(item => item.shop_item_id === shop_item_id);

      if (!item) {
        console.error('item not found', company, item, shop_item_id);
        
        return;
      }

      item.selected = true;
      company.show_error = false;

      this.local_companies = [ ...this.local_companies ];

      this.cart_items = this.cart_items.filter(item => item.shop_item_id !== previous_shop_item_id);

      this.cart_items.push(item);

      this.$emit('selected', this.cart_items);
    },
    removeCartItem(index, shop_item) {
      this.cart_items.splice(index, 1);

      for (const company of this.local_companies) {
        for (const item of company.items) {
          if (item.shop_item_id === shop_item.shop_item_id) {
            item.selected = false;
          }
        }
      }

      for (const item of this.local_extra_items) {
        if (item.shop_item_id === shop_item.shop_item_id) {
          item.selected = false;
        }
      }

      this.local_extra_items = [ ...this.local_extra_items ];

      this.local_companies = [ ...this.local_companies ];
    },
  },
};
</script>

<style scoped>
/* Container for both ItemSelector and CartSummary */
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

/* Common container styling for ItemSelector and CartSummary */
.section-container {
  width: 100%;
  max-width: 1200px; /* Limit the size on larger screens */
  margin: 20px 0;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

@media (max-width: 768px) {
  /* On mobile, the container will take the full width */
  .section-container {
    width: 100%;
    padding: 0;
    box-shadow: none; /* Optional: remove shadow on mobile for a flatter design */
    border-radius: 0;
  }
}
</style>